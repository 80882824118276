export const environment = {
  production: true,
  environment: 'production',
  baseURL: 'https://api.hexahq.com/api/customer',
  auth0: {
    client_id: 'FDrj1ZJ9zBGyoDPv8UmMIiZghweyDqcI',
    domain: 'hexa-customer.us.auth0.com',
    audience: 'https://hexa-customer.us.auth0.com/api/v2/',
    logout_url: 'https://customer.hexahq.com/auth/login',
    redirect_uri: 'https://customer.hexahq.com/callback'
  }
};
