import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
if (environment.environment !== 'local') {
  Sentry.init({
    dsn: 'https://5a499c1e6974434aaac52ff6b8f85aa2@o4504106901176320.ingest.sentry.io/4504106902749184',
    environment: environment.environment,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay(),
      new Sentry.BrowserTracing({
        tracingOrigins: ['localhost', 'https://api.hexahq.com/api'],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25
  });
}
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
