import { Injectable } from '@angular/core';
import { Auth0Client, createAuth0Client, GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { of, from, Observable, throwError, shareReplay, catchError, concatMap, map, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  auth0Client$: Observable<Auth0Client> = from(
    createAuth0Client({
      domain: environment.auth0.domain,
      clientId: environment.auth0.client_id,
      authorizationParams: {
        redirect_uri: environment.auth0.redirect_uri,
        audience: environment.auth0.audience
      }
    })
  ).pipe(
    shareReplay(1),
    catchError(err => throwError(() => new Error(err)))
  );
  isAuthenticated$ = this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.isAuthenticated())));
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );

  getTokenSilently$(): Observable<GetTokenSilentlyVerboseResponse> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently({ detailedResponse: true })))
    );
  }

  login(redirectPath?: string): Observable<void> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        client.loginWithRedirect({
          authorizationParams: {
            redirect_uri: environment.auth0.redirect_uri
          },
          appState: { target: redirectPath || location.pathname }
        })
      )
    );
  }

  handleAuthCallback(): Observable<{ loggedIn: boolean; targetUrl: string }> {
    return combineLatest({
      params: of(new URL(document.location.href).searchParams),
      redirectResponse: this.handleRedirectCallback$.pipe(
        concatMap(result => this.isAuthenticated$.pipe(map(loggedIn => ({ ...result, loggedIn }))))
      )
    }).pipe(
      concatMap(({ params, redirectResponse }) => {
        if (params.get('code') && params.get('state')) {
          return redirectResponse.loggedIn
            ? of({
                loggedIn: redirectResponse.loggedIn,
                targetUrl: '/home'
              })
            : of({ loggedIn: redirectResponse.loggedIn, targetUrl: '/callback' });
        } else {
          return of({ loggedIn: redirectResponse.loggedIn, targetUrl: '' });
        }
      })
    );
  }
}
